.tooltip {
    position: relative;
    display: inline-block;
    margin-left: 5px;
    cursor: pointer;
    color: #002855;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: auto;
    min-width: 230px;
    background-color: #e5e5e5;
    color: #005a8e;
    text-align: left;
    padding: 10px;
    border-radius: 6px;
    position: absolute;
    left: 10px;
    z-index: 1;
    top: -5px;
    margin: auto;
    z-index: 1055;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}
