@import '../../styles/mixin';

#internal-page {
    font-family: Titillium, serif;
    padding: 0 15px;
    background: var(--disabled);
    margin: 1rem 5rem;
    display: flex;
    flex-direction: column;

    @include desktop {
        border: 1px solid var(--disabled);
        border-radius: var(--border-radius);
        padding: 1rem;
    }
    @include tablet {
        margin: 1rem;
    }
    @include phone {
        margin: 10px;
        padding: 0;
        /* min-height: calc(100vh - var(--header-height) - 6em); */
        height: auto !important;
        .subtitle {
            padding: 0 15px;
        }
    }

    .title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0 0 20px 0;

        @include phone {
            flex-direction: column;
            margin-bottom: 15px;
        }
    }

    .title-content,
    .subtitle {
        color: var(--secondary);
    }

    .title-content {
        font-weight: bold;
        margin: 0;
    }

    .action-button {
        margin: 0 30px;

        button {
            font-size: 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        i {
            font-size: 14px;
        }
    }

    .subtitle {
        text-align: left;
        padding: 10px 0;
    }
}
