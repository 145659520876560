/* .text-area-contatti{
    grid-column: 1/4;
    .error-message{
        top: unset;
    }
    textarea{
        width: 100%;
        min-height:150px;
        padding: 8px;
        font-family: Titillium, serif;
    }
} */
.footer {
    display: flex;
    justify-content: flex-end;
}
