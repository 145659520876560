@import 'common';
// ***** Breakpoints for Responsive Screen
// width scale for phone

$desktop-min-width: 1025px;
$mobile-max-width: 576px;
$tablet-max-width: 1024px;
$tablet-min-width: 577px;

$phone-max-width: $mobile-max-width !default;
// Desktop Scale
$desktop-min-width: $desktop-min-width !default;
// Mixins
// For portrait phone and landscape phone

@mixin bg-colori-conciliazioni-mixin {
    @each $i in $colori-conciliazioni {
        &.#{nth($i, 1)} {
            $darken-color: darken(nth($i, 2), 50);
            $darkest-color: darken(nth($i, 2), 80);
            background-color: nth($i, 2);
            outline: 1px solid $darken-color;
            border-left: 3px solid $darken-color;
            border-radius: var(--border-radius);
            font-family: Titillium, sans-serif !important;

            &.selected {
                outline: 1px solid $darkest-color;
                border-left: 3px solid $darkest-color !important;
                font-weight: bold !important;
            }
        }
    }
}

@mixin bg-colori-sindacati-mixin {
    @each $i in $colori-sindacati {
        &.#{nth($i, 1)} {
            $darken-color: darken(nth($i, 2), 50);
            background-color: nth($i, 2);
            outline: 1px solid $darken-color;
            border-left: 3px solid $darken-color;
            border-radius: var(--border-radius);
            font-family: Titillium, sans-serif !important;
        }
    }
}

@mixin bg-light-colori-sindacati-mixin {
    @each $i in $colori-sindacati {
        &.#{nth($i, 1)} {
            $lighten-color: lighten(nth($i, 2), 35);
            $darken-color: darken(nth($i, 2), 50);
            outline: 1px solid nth($i, 2);
            background-color: $lighten-color;
        }
    }
}

@mixin border-colori-sindacati-mixin {
    @each $i in $colori-sindacati {
        &.#{nth($i, 1)} {
            outline: 1px solid nth($i, 2);
            border-left: 3px solid nth($i, 2);
            background: white;
            border-radius: var(--border-radius);
        }
    }
}

@mixin hover-colori-conciliazioni-mixin {
    @each $i in $colori-conciliazioni {
        &.#{nth($i, 1)} {
            &:hover {
                background-color: darken(nth($i, 2), 5);
            }
        }
    }
}

@mixin custom-file-input {
    input[type='file'] {
        display: none;
    }
    label {
        margin: 0 !important;
        color: var(--text);
        display: flex;
        justify-content: space-between;
        width: fit-content !important;

        &.custom-file-upload {
            display: flex !important;
            align-items: center !important;
            width: 100% !important;
            padding: 0 0 0 5px !important;
            height: var(--input-height);
            border-radius: var(--border-radius) !important;
            font-family: Titillium, serif;
            color: var(--secondary);
            margin-bottom: 0;
            border: 1px solid #e6e7e9;
            background: white;

            .filename-container {
                display: flex;
                align-items: center;
                width: 100%;

                .filename {
                    font-size: 12px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    width: 100%;
                }
            }

            .delete-file-icon {
                padding: 0 5px;
                cursor: pointer;
            }

            .upload-file {
                background: var(--primary) !important;
                width: 70px !important;
                height: 20px !important;
                font-size: 12px !important;
                border: none !important;
                color: white;
                border-radius: var(--border-radius) !important;
                font-family: Titillium, serif;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                &-icons {
                    display: flex;

                    &-icon {
                        cursor: pointer;
                        background: var(--background);
                        padding: 5px;
                        margin: 0 5px 0 0;
                        border-radius: var(--border-radius);
                    }
                }
            }
        }
    }
}

@mixin custom-input-select-date-button {
    input,
    select,
    .date,
    button {
        height: var(--input-height);
        width: 100%;
        border: 1px solid var(--background);
        border-radius: var(--border-radius) !important;
        padding-left: 8px;
        font-family: Titillium, serif;
        color: var(--secondary);

        &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 50px white inset;
        }

        &.react-datepicker__navigation {
            width: fit-content !important;
        }
    }
}

@mixin phone() {
    @media (max-width: $phone-max-width) {
        @content;
    }
}

// For portrait phone
@mixin portrait-phone() {
    @media (max-width: $phone-max-width) and (orientation: portrait) {
        @content;
    }
}

// For landscape phone
@mixin landscape-phone() {
    @media (max-width: $phone-max-width) and (orientation: landscape) {
        @content;
    }
}

@mixin tablet() {
    @media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
        @content;
    }
}

// For portrait tablet
@mixin portrait-tablet() {
    @media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) and (orientation: portrait) {
        @content;
    }
}

// For landscape tablet
@mixin landscape-tablet() {
    @media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) and (orientation: landscape) {
        @content;
    }
}

// For desktop
@mixin desktop() {
    @media (min-width: $desktop-min-width) {
        @content;
    }
}
