@import '../../styles/mixin';

#header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    border-bottom: 4px solid var(--accent);
    height: var(--header-height);

    .row-menu {
        display: flex;
        flex-direction: row;
        color: white;
        justify-content: flex-start;
        width: 100%;
        height: inherit;

        &-item {
            margin: auto 30px;
            font-size: 16px;
            cursor: pointer;
            font-family: Titillium, serif;

            &.active {
                color: var(--accent);
                text-decoration: underline;
                text-underline-offset: 5px;
            }

            &:hover {
                text-decoration: underline;
                text-underline-offset: 5px;
            }
        }
    }

    .logout {
        font-size: 20px;
        cursor: pointer;
    }

    img {
        height: 95px;
        margin-left: 30px;
    }

    .search {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &:focus-visible {
            outline: none;
        }

        input {
            width: 100%;
            background: transparent;
            border: none;
            border-bottom: 1px solid white;
            color: white;
            font-weight: 300 !important;
            font-size: 13px;
            font-family: Titillium, serif;
            padding: 5px;

            &::placeholder {
                font-family: Titillium, serif;
                font-style: normal;
                font-weight: 300 !important;
                color: white;
            }

            &:focus-visible {
                outline: none;
            }
        }

        i {
            position: absolute;
            color: white;
            cursor: pointer;
            @include phone {
                font-size: 16px !important;
            }
        }
    }

    .area-personale {
        padding: 30px;
        cursor: pointer;
        display: flex;
        align-items: center;

        i {
            font-size: 25px;
            width: 25px;
            color: white;
        }

        &-hello {
            cursor: default;
            color: white;
            display: inline-block;
            padding: 0 10px;

            span {
                font-style: italic;
                display: inline-block;
            }
        }

        .collapse-menu {
            border: 1px solid var(--accent);
            background: var(--secondary);
            color: white;
            border-radius: 5px;
            position: absolute;
            top: 66px;
            right: 27px;
            font-family: Titillium, serif;
            padding: 10px;
            z-index: 6;
            display: none;
            font-size: 14px;
            min-width: 150px;

            &.displayInitial {
                display: initial !important;
            }

            .lateral-menu {
                display: none;
            }

            .area-personale-item {
                display: flex;
                justify-content: flex-end;
                padding: 10px 0;
                @include phone {
                    padding: 10px;
                }

                @include desktop {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            @include phone {
                position: fixed;
                width: 100%;
                top: 99px;
                right: -100%;
                border-radius: 0;
                height: calc(100vh - 99px);
                font-size: 16px;
                transition: 0.2s right ease-in-out;
                display: block;

                &.displayInitial {
                    right: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 1148px) {
    #header {
        .row-menu {
            display: none !important;
        }

        .lateral-menu {
            display: flex !important;
            flex-direction: column;
        }
    }
}
