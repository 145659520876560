.custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 40, 85, 0.79);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1250;
    cursor: default;
    overflow: auto;
}

.custom-modal .input-number {
    height: 26px;
    font-size: 14px;
    line-height: 14px;
}
.custom-modal.hide {
    display: none;
}
.custom-modal.show {
    display: flex;
}
.custom-modal.sm .modal-wrapper {
    width: 30% !important;
}
.custom-modal.md .modal-wrapper {
    width: 50%;
}
.custom-modal.lg .modal-wrapper {
    width: 90%;
}
.custom-modal .modal-wrapper {
    background-color: white;
    color: var(--testo);
    border-radius: 0.3rem;
    padding: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 90vh;
}
.custom-modal .modal-wrapper.width-fit-content {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
}
.custom-modal .modal-wrapper .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.custom-modal .modal-wrapper .modal-header .title {
    font-family: Titillium, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: -0.3px;
    color: var(--secondary);
    text-align: left;
}
.custom-modal .modal-wrapper .modal-header .fa-times {
    cursor: pointer;
    color: var(--danger);
}
.custom-modal .modal-wrapper .modal-content {
    text-align: left;
}
.custom-modal .modal-wrapper .modal-footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
}
.custom-modal .modal-wrapper .modal-footer button {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    padding: 0 15px !important;
    background: var(--primary);
    color: white;
}
.custom-modal .modal-wrapper .modal-footer button.elimina-button {
    background-color: white !important;
    color: var(--text) !important;
    border: 2px solid var(--danger);
    margin: 0 auto 0 0;
}
.custom-modal .modal-wrapper .modal-footer button:last-child {
    background: var(--primary);
}
@media (max-width: 576px) {
  .custom-modal {
      padding: 0;
      position: fixed;
  }
  .custom-modal.lg .modal-wrapper {
    width: 100%;
    
}
}