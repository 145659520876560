@font-face {
    font-family: Titillium;
    src: url(../assets/fonts/Titillium_Web/TitilliumWeb-Regular.ttf) format('truetype');
}
@font-face {
    font-family: Titillium-SemiBold;
    src: url(../assets/fonts/Titillium_Web/TitilliumWeb-SemiBold.ttf) format('truetype');
}
@font-face {
    font-family: Titillium-Bold;
    src: url(../assets/fonts/Titillium_Web/TitilliumWeb-Bold.ttf) format('truetype');
}
body {
    font-family: Poppins, sans-serif !important;
    color: var(--testo);
}
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
    font-weight: bold;
}

.d-flex {
    display: flex;
}

.d-inline-block {
    display: inline-block;
}

.centerd-content {
    justify-content: center;
    display: flex;
    width: 100%;
}

.btn-container {
    display: flex;
    justify-content: flex-end;
}
.btn-container button:last-of-type {
    margin-left: 10px;
}

.title-centered {
    text-align: center;
    margin-top: 0;
}

.cursor-pointer {
    cursor: pointer;
}

.back-icon {
    display: flex;
    align-items: center;
}
.back-icon svg {
    margin-right: 6px;
}

.loading-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    background-color: black;
    display: flex;
    align-content: center;
    justify-content: center;
    color: white;
    font-size: 18px;
    font-style: italic;
    flex-wrap: wrap;
    opacity: 0.75;
    z-index: 10000;
}
.loading-container img {
    height: 30vh;
    width: 100%;
}

.floating-icon-wrapper-right,
.floating-icon-wrapper-left {
    width: 42px;
    height: var(--input-height);
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.floating-icon-wrapper-right {
    right: 0;
}

.floating-icon-wrapper-left {
    left: 0;
}

.list-item-text {
    font-size: 18px;
}

.common-template-container {
    height: 100%;
}

hr {
    background: var(--secondary);
    height: 1px;
    border: none;
    width: 100%;
}

.form-row {
    display: grid;
    grid-column-gap: 15px;
    grid-row-gap: 10px;
}
.form-row.grid-1 {
    grid-template-columns: 100%;
}
.form-row.grid-2 {
    grid-template-columns: calc((100% - 15px) / 2) calc((100% - 15px) / 2);
}
.form-row.grid-2 hr {
    grid-column: 1/3;
}
.form-row.grid-3 {
    grid-template-columns: calc((100% - 30px) / 3) calc((100% - 30px) / 3) calc((100% - 30px) / 3);
}
@media (min-width: 577px) and (max-width: 1024px) {
    .form-row.grid-3 {
        grid-template-columns: 100%;
    }
}
@media (max-width: 576px) {
    .form-row.grid-3 {
        grid-template-columns: 1fr;/* 100%; */
    }
}
.form-row.grid-3 hr {
    grid-column: 1/4;
}
.form-row.grid-4 {
    grid-template-columns: calc((100% - 45px) / 4) calc((100% - 45px) / 4) calc((100% - 45px) / 4) calc(
            (100% - 45px) / 4
        );
}
.form-row.grid-4 hr {
    grid-column: 1/5;
}
.form-row-element.p-0 {
    padding: 0;
}
.form-row-element.w-100 {
    width: 100% !important;
}
.form-row-element.w-50 {
    width: 50% !important;
}
.form-row-element.w-50.maxMarginRight {
    margin-right: 50% !important;
}
.form-row-element.w-25 {
    width: 25% !important;
}
.form-row-element.w-25.maxMarginRight {
    margin-right: 75% !important;
}
.form-row-element.w-25.quarterMarginRight {
    margin-right: 25% !important;
}
.form-row-element.w-two-thirds {
    width: 66.6666666667% !important;
}
.form-row-element.w-two-thirds.maxMarginRight {
    margin-right: 33.3333333333% !important;
}
.form-row-element.w-third {
    width: 33.3333333333% !important;
}
.form-row-element.w-third.marginRight {
    margin-right: 33.3333333333% !important;
}
.form-row-element.w-third.maxMarginRight {
    margin-right: 66.6666666667% !important;
}
.form-row-element.w-third-half {
    width: 16.6666666667% !important;
}
.form-row-element.marginRight10 {
    margin-right: 10px;
}
.form-row-element label {
    display: inline-block;
    width: 100% !important;
    color: var(--primary);
    font-family: Titillium, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: -0.5px;
    text-align: left;
}
.form-row .input-wrapper {
    position: relative;
    width: 100%;
    padding-bottom: 15px;
}
.form-row .input-wrapper input[type='number'] {
    -moz-appearance: textfield;
}
.form-row .input-wrapper input::-webkit-outer-spin-button,
.form-row .input-wrapper input::-webkit-inner-spin-button {
    margin-left: 20px;
}
.form-row .input-wrapper input[type='date']::-webkit-calendar-picker-indicator {
    margin: 0;
}
.form-row .input-wrapper input[type='file'] {
    display: none;
}
.form-row .input-wrapper label {
    margin: 0 !important;
    color: var(--text);
    display: flex;
    justify-content: space-between;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
}
.form-row .input-wrapper label.custom-file-upload {
    display: flex !important;
    align-items: center !important;
    width: 100% !important;
    padding: 0 0 0 5px !important;
    height: var(--input-height);
    border-radius: var(--border-radius) !important;
    font-family: Titillium, serif;
    color: var(--secondary);
    margin-bottom: 0;
    border: 1px solid #e6e7e9;
    background: white;
}
.form-row .input-wrapper label.custom-file-upload .filename-container {
    display: flex;
    align-items: center;
    width: 100%;
}
.form-row .input-wrapper label.custom-file-upload .filename-container .filename {
    font-size: 12px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 100%;
}
.form-row .input-wrapper label.custom-file-upload .delete-file-icon {
    padding: 0 5px;
    cursor: pointer;
}
.form-row .input-wrapper label.custom-file-upload .upload-file {
    background: var(--primary) !important;
    width: 70px !important;
    height: 20px !important;
    font-size: 12px !important;
    border: none !important;
    color: white;
    border-radius: var(--border-radius) !important;
    font-family: Titillium, serif;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.form-row .input-wrapper label.custom-file-upload .upload-file-icons {
    display: flex;
}
.form-row .input-wrapper label.custom-file-upload .upload-file-icons-icon {
    cursor: pointer;
    background: var(--background);
    padding: 5px;
    margin: 0 5px 0 0;
    border-radius: var(--border-radius);
}
.form-row .input-wrapper input,
.form-row .input-wrapper select,
.form-row .input-wrapper .date,
.form-row .input-wrapper button {
    height: var(--input-height);
    width: 100%;
    border: 1px solid var(--background);
    border-radius: var(--border-radius) !important;
    padding-left: 8px;
    font-family: Titillium, serif;
    color: var(--secondary);
}
.form-row .input-wrapper input:-webkit-autofill,
.form-row .input-wrapper select:-webkit-autofill,
.form-row .input-wrapper .date:-webkit-autofill,
.form-row .input-wrapper button:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px white inset;
}
.form-row .input-wrapper input.react-datepicker__navigation,
.form-row .input-wrapper select.react-datepicker__navigation,
.form-row .input-wrapper .date.react-datepicker__navigation,
.form-row .input-wrapper button.react-datepicker__navigation {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
}
.form-row .input-wrapper:hover input {
    cursor: default;
}
.form-row .input-wrapper .custom-select .select-input {
    cursor: pointer;
    height: var(--input-height);
    width: 100%;
    border: 1px solid #e6e7e9;
    padding-left: 8px;
    font-family: Titillium, serif;
    color: var(--secondary);
    border-radius: 0.25rem;
    outline: none;
    transition: var(--sidebar-transition) all;
    display: flex;
    align-items: center;
    font-size: 13.3333px;
    background-color: white;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden !important;
    margin-left: 0;
    padding-right: 20px;
}
.form-row .input-wrapper .custom-select .select-arrow {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}
.form-row .input-wrapper .custom-select .options {
    background: white;
    border: 1px solid var(--background);
    border-radius: 4px;
    position: absolute;
    top: var(--input-height);
    left: 0;
    width: 100%;
    z-index: 5;
    overflow: hidden;
    overflow-y: scroll;
    max-height: 150px;
}
.form-row .input-wrapper .custom-select .options .option {
    padding: 5px 10px;
    cursor: pointer;
    font-size: 13px;
    color: var(--secondary);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.form-row .input-wrapper .custom-select .options .option .icon:hover {
    font-size: 15px;
}
.form-row .input-wrapper .custom-select .options .option.unselectable {
    background: var(--disabled) !important;
    color: var(--testo);
    cursor: default !important;
}
.form-row .input-wrapper .custom-select .options .option.unselectable:hover {
    background: var(--disabled) !important;
    color: var(--testo) !important;
}
.form-row .input-wrapper .custom-select .options .option:hover {
    background: var(--background) !important;
    border-radius: 4px;
}
.form-row .input-wrapper .custom-select .add-impresa {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.form-row .input-wrapper .custom-select .add-impresa-button {
    background: var(--primary);
    color: white;
    font-size: 8px;
    padding: 5px;
    border-radius: 4px;
}
.form-row .clear-field,
.form-row .show-password,
.form-row .floating-icon-right {
    position: absolute;
    right: 8px;
    cursor: pointer;
}
.form-row .floating-icon-right,
.form-row .floating-icon-left {
    font-size: 12px;
    top: 12px;
}
.form-row label {
    width: 100%;
    font-size: 14px;
}
.form-row input,
.form-row textarea {
    width: 100%;
    height: var(--input-height);
    border-radius: 5px;
    padding-right: 8px;
    padding-left: 8px;
    outline: none;
    border: 1px solid var(--background);
    transition: var(--sidebar-transition) all;
    box-sizing: border-box;
}

.stato-conciliazione {
    width: 100%;
    padding: 0 10px !important;
}
.stato-conciliazione.PAUSA {
    background-color: #f4f6f6;
    outline: 1px solid #6a8181;
    border-left: 3px solid #6a8181;
    border-radius: var(--border-radius);
    font-family: Titillium, sans-serif !important;
}
.stato-conciliazione.PAUSA.selected {
    outline: 1px solid #252d2d;
    border-left: 3px solid #252d2d !important;
    font-weight: bold !important;
}
.stato-conciliazione.COLLETTIVA {
    background-color: #f0f4c3;
    outline: 1px solid #919b1d;
    border-left: 3px solid #919b1d;
    border-radius: var(--border-radius);
    font-family: Titillium, sans-serif !important;
}
.stato-conciliazione.COLLETTIVA.selected {
    outline: 1px solid #181a05;
    border-left: 3px solid #181a05 !important;
    font-weight: bold !important;
}
.stato-conciliazione.NON_ACCETTATA {
    background-color: #fadbd8;
    outline: 1px solid #bb2618;
    border-left: 3px solid #bb2618;
    border-radius: var(--border-radius);
    font-family: Titillium, sans-serif !important;
}
.stato-conciliazione.NON_ACCETTATA.selected {
    outline: 1px solid #330b07;
    border-left: 3px solid #330b07 !important;
    font-weight: bold !important;
}
.stato-conciliazione.ACCETTATA {
    background-color: #cffff6;
    outline: 1px solid #00cfa8;
    border-left: 3px solid #00cfa8;
    border-radius: var(--border-radius);
    font-family: Titillium, sans-serif !important;
}
.stato-conciliazione.ACCETTATA.selected {
    outline: 1px solid #00362c;
    border-left: 3px solid #00362c !important;
    font-weight: bold !important;
}
.stato-conciliazione.BOZZA {
    background-color: #d9f5ff;
    outline: 1px solid #00a0d9;
    border-left: 3px solid #00a0d9;
    border-radius: var(--border-radius);
    font-family: Titillium, sans-serif !important;
}
.stato-conciliazione.BOZZA.selected {
    outline: 1px solid #002f40;
    border-left: 3px solid #002f40 !important;
    font-weight: bold !important;
}
.stato-conciliazione.IN_PENDING {
    background-color: #fcf3cf;
    outline: 1px solid #c09c0c;
    border-left: 3px solid #c09c0c;
    border-radius: var(--border-radius);
    font-family: Titillium, sans-serif !important;
}
.stato-conciliazione.IN_PENDING.selected {
    outline: 1px solid #302703;
    border-left: 3px solid #302703 !important;
    font-weight: bold !important;
}
.stato-conciliazione.CHIUSA {
    background-color: #cffcdc;
    outline: 1px solid #0cc040;
    border-left: 3px solid #0cc040;
    border-radius: var(--border-radius);
    font-family: Titillium, sans-serif !important;
}
.stato-conciliazione.CHIUSA.selected {
    outline: 1px solid #033010;
    border-left: 3px solid #033010 !important;
    font-weight: bold !important;
}
.stato-conciliazione.CONCLUSA {
    background-color: #cfd8fc;
    outline: 1px solid #0c30c0;
    border-left: 3px solid #0c30c0;
    border-radius: var(--border-radius);
    font-family: Titillium, sans-serif !important;
}
.stato-conciliazione.CONCLUSA.selected {
    outline: 1px solid #030c30;
    border-left: 3px solid #030c30 !important;
    font-weight: bold !important;
}
.stato-conciliazione.NON_SVOLTA {
    background-color: #fccfef;
    outline: 1px solid #c00c8c;
    border-left: 3px solid #c00c8c;
    border-radius: var(--border-radius);
    font-family: Titillium, sans-serif !important;
}
.stato-conciliazione.NON_SVOLTA.selected {
    outline: 1px solid #300323;
    border-left: 3px solid #300323 !important;
    font-weight: bold !important;
}
.stato-conciliazione.OCCUPIED {
    background-color: #dfdfdf;
    outline: 1px solid #606060;
    border-left: 3px solid #606060;
    border-radius: var(--border-radius);
    font-family: Titillium, sans-serif !important;
}
.stato-conciliazione.OCCUPIED.selected {
    outline: 1px solid #131313;
    border-left: 3px solid #131313 !important;
    font-weight: bold !important;
}
.stato-conciliazione.DEFAULT-COLOR {
    background-color: #d6fee6;
    outline: 1px solid #05d056;
    border-left: 3px solid #05d056;
    border-radius: var(--border-radius);
    font-family: Titillium, sans-serif !important;
}
.stato-conciliazione.DEFAULT-COLOR.selected {
    outline: 1px solid #013b18;
    border-left: 3px solid #013b18 !important;
    font-weight: bold !important;
}
.stato-conciliazione.hover.PAUSA:hover {
    background-color: #e6ebeb;
}
.stato-conciliazione.hover.COLLETTIVA:hover {
    background-color: #ebf0ad;
}
.stato-conciliazione.hover.NON_ACCETTATA:hover {
    background-color: #f7c6c1;
}
.stato-conciliazione.hover.ACCETTATA:hover {
    background-color: #b6fff1;
}
.stato-conciliazione.hover.BOZZA:hover {
    background-color: #c0eeff;
}
.stato-conciliazione.hover.IN_PENDING:hover {
    background-color: #fbedb7;
}
.stato-conciliazione.hover.CHIUSA:hover {
    background-color: #b7fbcb;
}
.stato-conciliazione.hover.CONCLUSA:hover {
    background-color: #b7c5fb;
}
.stato-conciliazione.hover.NON_SVOLTA:hover {
    background-color: #fbb7e7;
}
.stato-conciliazione.hover.OCCUPIED:hover {
    background-color: #d2d2d2;
}
.stato-conciliazione.hover.DEFAULT-COLOR:hover {
    background-color: #bdfdd7;
}

.empty-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-top: calc(var(--input-height) + 10px);
    font-size: 20px;
    color: var(--secondary);
}

@media (min-width: 1025px) {
    form {
        width: 100%;
    }
}
@media (min-width: 577px) and (max-width: 1024px) {
    form {
        width: 100%;
    }
}

.go-back {
    z-index: 5;
    width: 20px;
    font-size: 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 5px;
}

.overflow-hidden {
    overflow: hidden;
    overflow-y: scroll;
}

.react-datepicker__triangle {
    left: 0;
    transform: translate3d(45px, 0px, 0px);
}

.th,
.td {
    color: var(--text);
    padding: 5px;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.tr {
    display: flex;
    width: 100%;
}

.table-header {
    display: flex;
    border-bottom: 1px solid var(--background);
}
.table-header .th {
    font-weight: bold;
    display: flex;
    align-items: flex-start;
    overflow: hidden;
}

.table-body {
    display: flex;
    flex-direction: column;
}
.table-body .tr.container {
    flex-direction: column;
    border: 1px solid var(--testo);
    border-radius: 0.25rem;
    margin-bottom: 15px;
    cursor: pointer;
}
.table-body .tr.container .tr {
    border-radius: 0.25rem;
}

.text-area-contatti {
    grid-column: 1/4;
}
.text-area-contatti .error-message {
    top: unset;
}
.text-area-contatti textarea {
    width: 100%;
    min-height: 150px;
    padding: 8px;
    font-family: Titillium, serif;
}

:root {
    --background: #e5e5e5;
    --danger: #db6157;
    --disabled: #f5f5f5;
    --header-height: 80px;
    --primary: #005a8e;
    --secondary: #002855;
    --sidebar-collapsed-width: 50px;
    --sidebar-width: 250px;
    --sidebar-transition: 0.3s;
    --success: #4dbc68;
    --testo: #767676;
    --warning: #f6cf4e;
    --text: #181819;
    --accent: #6495ed;
    --accent-secondary: #cbd4e4;
    --accent-light: rgba(151, 169, 201, 0.5);
    --border-radius: 0.25rem;
    --input-height: 35px;
}

.date,
button {
    padding: 0 15px;
    font-weight: 200;
    font-family: Titillium, serif;
    padding: 0 20px;
    height: 36px;
    font-size: 0.8rem;
    border-radius: 6px;
}
.date:-webkit-autofill,
button:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px white inset;
}

select {
    padding: 0 5px;
    font-weight: 200;
    font-family: Titillium, serif;
    height: 36px;
    font-size: 0.8rem;
    border-radius: 6px;
}
select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px white inset;
}

button {
    background: var(--secondary);
    color: white !important;
    border: none;
    cursor: pointer !important;
}

.btn-sm {
    padding: 0 15px;
    height: 28px;
    font-size: 0.6rem;
    border-radius: 14px;
}

.btn-lg {
    padding: 0 25px;
    height: 44px;
    font-size: 1rem;
    border-radius: 7.3333333333px;
}

.overlay-disabled-05:after {
    opacity: 0.05;
    background: #f5f5f5;
}

.overlay-disabled-1:after {
    opacity: 0.1;
    background: #f5f5f5;
}

.overlay-disabled-15:after {
    opacity: 0.15;
    background: #f5f5f5;
}

.overlay-disabled-2:after {
    opacity: 0.2;
    background: #f5f5f5;
}

.overlay-disabled-25:after {
    opacity: 0.25;
    background: #f5f5f5;
}

.overlay-disabled-3:after {
    opacity: 0.3;
    background: #f5f5f5;
}

.overlay-disabled-35:after {
    opacity: 0.35;
    background: #f5f5f5;
}

.overlay-disabled-4:after {
    opacity: 0.4;
    background: #f5f5f5;
}

.overlay-disabled-45:after {
    opacity: 0.45;
    background: #f5f5f5;
}

.overlay-disabled-5:after {
    opacity: 0.5;
    background: #f5f5f5;
}

.overlay-disabled-55:after {
    opacity: 0.55;
    background: #f5f5f5;
}

.overlay-disabled-6:after {
    opacity: 0.6;
    background: #f5f5f5;
}

.overlay-disabled-65:after {
    opacity: 0.65;
    background: #f5f5f5;
}

.overlay-disabled-7:after {
    opacity: 0.7;
    background: #f5f5f5;
}

.overlay-disabled-75:after {
    opacity: 0.75;
    background: #f5f5f5;
}

.overlay-disabled-8:after {
    opacity: 0.8;
    background: #f5f5f5;
}

.overlay-disabled-85:after {
    opacity: 0.85;
    background: #f5f5f5;
}

.overlay-disabled-9:after {
    opacity: 0.9;
    background: #f5f5f5;
}

.overlay-disabled-95:after {
    opacity: 0.95;
    background: #f5f5f5;
}

.overlay-disabled-100:after {
    opacity: 1;
    background: #f5f5f5;
}

.btn-disabled {
    background-color: #f5f5f5 !important;
    border-color: #f5f5f5 !important;
    color: white !important;
}
.btn-disabled .fa,
.btn-disabled .fas,
.btn-disabled .far,
.btn-disabled a {
    color: white !important;
}
.btn-disabled .dropdown-item {
    color: black !important;
}
.btn-disabled:focus {
    box-shadow: none;
}
.btn-disabled:hover {
    color: #f5f5f5 !important;
    background-color: white !important;
    border: 1px solid #f5f5f5;
}
.btn-disabled:hover .dropdown-item {
    color: black !important;
    background-color: white;
}
.btn-disabled:hover .dropdown-item:hover {
    background-color: #f5f5f5 !important;
    color: white !important;
}
.btn-disabled:hover .fa,
.btn-disabled:hover .fas,
.btn-disabled:hover .far,
.btn-disabled:hover a {
    color: #f5f5f5 !important;
}

.outline-disabled {
    color: #f5f5f5 !important;
    background-color: transparent;
    background-image: none;
    border-color: #f5f5f5 !important;
}
.outline-disabled .fa,
.outline-disabled .fas,
.outline-disabled .far,
.outline-disabled a {
    color: #f5f5f5 !important;
}
.outline-disabled:hover {
    color: white !important;
    background-color: #f5f5f5 !important;
    border-color: #f5f5f5 !important;
}
.outline-disabled:hover .fa,
.outline-disabled:hover .fas,
.outline-disabled:hover .far,
.outline-disabled:hover a {
    color: white !important;
}
.outline-disabled:focus {
    box-shadow: none;
}

.badge-disabled {
    background-color: #f5f5f5 !important;
    border: 1px solid #f5f5f5 !important;
    color: white !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.badge-disabled-lighten-5 {
    background-color: white !important;
    border: 1px solid white !important;
    color: white !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.badge-disabled-lighten-10 {
    background-color: white !important;
    border: 1px solid white !important;
    color: white !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.badge-outline-disabled {
    background-color: var(--background) !important;
    color: #f5f5f5 !important;
    border: 1px solid #f5f5f5 !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}
.badge-outline-disabled:hover {
    background-color: #f5f5f5 !important;
    color: white !important;
    cursor: pointer;
}

.status-disabled {
    border-color: #f5f5f5 !important;
}

.bg-disabled {
    background-color: #f5f5f5 !important;
}

.color-disabled {
    color: #f5f5f5 !important;
}

.overlay-background-05:after {
    opacity: 0.05;
    background: #e5e5e5;
}

.overlay-background-1:after {
    opacity: 0.1;
    background: #e5e5e5;
}

.overlay-background-15:after {
    opacity: 0.15;
    background: #e5e5e5;
}

.overlay-background-2:after {
    opacity: 0.2;
    background: #e5e5e5;
}

.overlay-background-25:after {
    opacity: 0.25;
    background: #e5e5e5;
}

.overlay-background-3:after {
    opacity: 0.3;
    background: #e5e5e5;
}

.overlay-background-35:after {
    opacity: 0.35;
    background: #e5e5e5;
}

.overlay-background-4:after {
    opacity: 0.4;
    background: #e5e5e5;
}

.overlay-background-45:after {
    opacity: 0.45;
    background: #e5e5e5;
}

.overlay-background-5:after {
    opacity: 0.5;
    background: #e5e5e5;
}

.overlay-background-55:after {
    opacity: 0.55;
    background: #e5e5e5;
}

.overlay-background-6:after {
    opacity: 0.6;
    background: #e5e5e5;
}

.overlay-background-65:after {
    opacity: 0.65;
    background: #e5e5e5;
}

.overlay-background-7:after {
    opacity: 0.7;
    background: #e5e5e5;
}

.overlay-background-75:after {
    opacity: 0.75;
    background: #e5e5e5;
}

.overlay-background-8:after {
    opacity: 0.8;
    background: #e5e5e5;
}

.overlay-background-85:after {
    opacity: 0.85;
    background: #e5e5e5;
}

.overlay-background-9:after {
    opacity: 0.9;
    background: #e5e5e5;
}

.overlay-background-95:after {
    opacity: 0.95;
    background: #e5e5e5;
}

.overlay-background-100:after {
    opacity: 1;
    background: #e5e5e5;
}

.btn-background {
    background-color: #e5e5e5 !important;
    border-color: #e5e5e5 !important;
    color: white !important;
}
.btn-background .fa,
.btn-background .fas,
.btn-background .far,
.btn-background a {
    color: white !important;
}
.btn-background .dropdown-item {
    color: black !important;
}
.btn-background:focus {
    box-shadow: none;
}
.btn-background:hover {
    color: #e5e5e5 !important;
    background-color: white !important;
    border: 1px solid #e5e5e5;
}
.btn-background:hover .dropdown-item {
    color: black !important;
    background-color: white;
}
.btn-background:hover .dropdown-item:hover {
    background-color: #e5e5e5 !important;
    color: white !important;
}
.btn-background:hover .fa,
.btn-background:hover .fas,
.btn-background:hover .far,
.btn-background:hover a {
    color: #e5e5e5 !important;
}

.outline-background {
    color: #e5e5e5 !important;
    background-color: transparent;
    background-image: none;
    border-color: #e5e5e5 !important;
}
.outline-background .fa,
.outline-background .fas,
.outline-background .far,
.outline-background a {
    color: #e5e5e5 !important;
}
.outline-background:hover {
    color: white !important;
    background-color: #e5e5e5 !important;
    border-color: #e5e5e5 !important;
}
.outline-background:hover .fa,
.outline-background:hover .fas,
.outline-background:hover .far,
.outline-background:hover a {
    color: white !important;
}
.outline-background:focus {
    box-shadow: none;
}

.badge-background {
    background-color: #e5e5e5 !important;
    border: 1px solid #e5e5e5 !important;
    color: white !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.badge-background-lighten-5 {
    background-color: #f2f2f2 !important;
    border: 1px solid #f2f2f2 !important;
    color: white !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.badge-background-lighten-10 {
    background-color: white !important;
    border: 1px solid white !important;
    color: white !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.badge-outline-background {
    background-color: var(--background) !important;
    color: #e5e5e5 !important;
    border: 1px solid #e5e5e5 !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}
.badge-outline-background:hover {
    background-color: #e5e5e5 !important;
    color: white !important;
    cursor: pointer;
}

.status-background {
    border-color: #e5e5e5 !important;
}

.bg-background {
    background-color: #e5e5e5 !important;
}

.color-background {
    color: #e5e5e5 !important;
}

.overlay-danger-05:after {
    opacity: 0.05;
    background: #db6157;
}

.overlay-danger-1:after {
    opacity: 0.1;
    background: #db6157;
}

.overlay-danger-15:after {
    opacity: 0.15;
    background: #db6157;
}

.overlay-danger-2:after {
    opacity: 0.2;
    background: #db6157;
}

.overlay-danger-25:after {
    opacity: 0.25;
    background: #db6157;
}

.overlay-danger-3:after {
    opacity: 0.3;
    background: #db6157;
}

.overlay-danger-35:after {
    opacity: 0.35;
    background: #db6157;
}

.overlay-danger-4:after {
    opacity: 0.4;
    background: #db6157;
}

.overlay-danger-45:after {
    opacity: 0.45;
    background: #db6157;
}

.overlay-danger-5:after {
    opacity: 0.5;
    background: #db6157;
}

.overlay-danger-55:after {
    opacity: 0.55;
    background: #db6157;
}

.overlay-danger-6:after {
    opacity: 0.6;
    background: #db6157;
}

.overlay-danger-65:after {
    opacity: 0.65;
    background: #db6157;
}

.overlay-danger-7:after {
    opacity: 0.7;
    background: #db6157;
}

.overlay-danger-75:after {
    opacity: 0.75;
    background: #db6157;
}

.overlay-danger-8:after {
    opacity: 0.8;
    background: #db6157;
}

.overlay-danger-85:after {
    opacity: 0.85;
    background: #db6157;
}

.overlay-danger-9:after {
    opacity: 0.9;
    background: #db6157;
}

.overlay-danger-95:after {
    opacity: 0.95;
    background: #db6157;
}

.overlay-danger-100:after {
    opacity: 1;
    background: #db6157;
}

.btn-danger {
    background-color: #db6157 !important;
    border-color: #db6157 !important;
    color: white !important;
}
.btn-danger .fa,
.btn-danger .fas,
.btn-danger .far,
.btn-danger a {
    color: white !important;
}
.btn-danger .dropdown-item {
    color: black !important;
}
.btn-danger:focus {
    box-shadow: none;
}
.btn-danger:hover {
    color: #db6157 !important;
    background-color: white !important;
    border: 1px solid #db6157;
}
.btn-danger:hover .dropdown-item {
    color: black !important;
    background-color: white;
}
.btn-danger:hover .dropdown-item:hover {
    background-color: #db6157 !important;
    color: white !important;
}
.btn-danger:hover .fa,
.btn-danger:hover .fas,
.btn-danger:hover .far,
.btn-danger:hover a {
    color: #db6157 !important;
}

.outline-danger {
    color: #db6157 !important;
    background-color: transparent;
    background-image: none;
    border-color: #db6157 !important;
}
.outline-danger .fa,
.outline-danger .fas,
.outline-danger .far,
.outline-danger a {
    color: #db6157 !important;
}
.outline-danger:hover {
    color: white !important;
    background-color: #db6157 !important;
    border-color: #db6157 !important;
}
.outline-danger:hover .fa,
.outline-danger:hover .fas,
.outline-danger:hover .far,
.outline-danger:hover a {
    color: white !important;
}
.outline-danger:focus {
    box-shadow: none;
}

.badge-danger {
    background-color: #db6157 !important;
    border: 1px solid #db6157 !important;
    color: white !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.badge-danger-lighten-5 {
    background-color: #e0756c !important;
    border: 1px solid #e0756c !important;
    color: white !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.badge-danger-lighten-10 {
    background-color: #e48981 !important;
    border: 1px solid #e48981 !important;
    color: white !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.badge-outline-danger {
    background-color: var(--background) !important;
    color: #db6157 !important;
    border: 1px solid #db6157 !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}
.badge-outline-danger:hover {
    background-color: #db6157 !important;
    color: white !important;
    cursor: pointer;
}

.status-danger {
    border-color: #db6157 !important;
}

.bg-danger {
    background-color: #db6157 !important;
}

.color-danger {
    color: #db6157 !important;
}

.overlay-testo-05:after {
    opacity: 0.05;
    background: #767676;
}

.overlay-testo-1:after {
    opacity: 0.1;
    background: #767676;
}

.overlay-testo-15:after {
    opacity: 0.15;
    background: #767676;
}

.overlay-testo-2:after {
    opacity: 0.2;
    background: #767676;
}

.overlay-testo-25:after {
    opacity: 0.25;
    background: #767676;
}

.overlay-testo-3:after {
    opacity: 0.3;
    background: #767676;
}

.overlay-testo-35:after {
    opacity: 0.35;
    background: #767676;
}

.overlay-testo-4:after {
    opacity: 0.4;
    background: #767676;
}

.overlay-testo-45:after {
    opacity: 0.45;
    background: #767676;
}

.overlay-testo-5:after {
    opacity: 0.5;
    background: #767676;
}

.overlay-testo-55:after {
    opacity: 0.55;
    background: #767676;
}

.overlay-testo-6:after {
    opacity: 0.6;
    background: #767676;
}

.overlay-testo-65:after {
    opacity: 0.65;
    background: #767676;
}

.overlay-testo-7:after {
    opacity: 0.7;
    background: #767676;
}

.overlay-testo-75:after {
    opacity: 0.75;
    background: #767676;
}

.overlay-testo-8:after {
    opacity: 0.8;
    background: #767676;
}

.overlay-testo-85:after {
    opacity: 0.85;
    background: #767676;
}

.overlay-testo-9:after {
    opacity: 0.9;
    background: #767676;
}

.overlay-testo-95:after {
    opacity: 0.95;
    background: #767676;
}

.overlay-testo-100:after {
    opacity: 1;
    background: #767676;
}

.btn-testo {
    background-color: #767676 !important;
    border-color: #767676 !important;
    color: white !important;
}
.btn-testo .fa,
.btn-testo .fas,
.btn-testo .far,
.btn-testo a {
    color: white !important;
}
.btn-testo .dropdown-item {
    color: black !important;
}
.btn-testo:focus {
    box-shadow: none;
}
.btn-testo:hover {
    color: #767676 !important;
    background-color: white !important;
    border: 1px solid #767676;
}
.btn-testo:hover .dropdown-item {
    color: black !important;
    background-color: white;
}
.btn-testo:hover .dropdown-item:hover {
    background-color: #767676 !important;
    color: white !important;
}
.btn-testo:hover .fa,
.btn-testo:hover .fas,
.btn-testo:hover .far,
.btn-testo:hover a {
    color: #767676 !important;
}

.outline-testo {
    color: #767676 !important;
    background-color: transparent;
    background-image: none;
    border-color: #767676 !important;
}
.outline-testo .fa,
.outline-testo .fas,
.outline-testo .far,
.outline-testo a {
    color: #767676 !important;
}
.outline-testo:hover {
    color: white !important;
    background-color: #767676 !important;
    border-color: #767676 !important;
}
.outline-testo:hover .fa,
.outline-testo:hover .fas,
.outline-testo:hover .far,
.outline-testo:hover a {
    color: white !important;
}
.outline-testo:focus {
    box-shadow: none;
}

.badge-testo {
    background-color: #767676 !important;
    border: 1px solid #767676 !important;
    color: white !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.badge-testo-lighten-5 {
    background-color: #838383 !important;
    border: 1px solid #838383 !important;
    color: white !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.badge-testo-lighten-10 {
    background-color: #909090 !important;
    border: 1px solid #909090 !important;
    color: white !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.badge-outline-testo {
    background-color: var(--background) !important;
    color: #767676 !important;
    border: 1px solid #767676 !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}
.badge-outline-testo:hover {
    background-color: #767676 !important;
    color: white !important;
    cursor: pointer;
}

.status-testo {
    border-color: #767676 !important;
}

.bg-testo {
    background-color: #767676 !important;
}

.color-testo {
    color: #767676 !important;
}

.overlay-primary-05:after {
    opacity: 0.05;
    background: #005a8e;
}

.overlay-primary-1:after {
    opacity: 0.1;
    background: #005a8e;
}

.overlay-primary-15:after {
    opacity: 0.15;
    background: #005a8e;
}

.overlay-primary-2:after {
    opacity: 0.2;
    background: #005a8e;
}

.overlay-primary-25:after {
    opacity: 0.25;
    background: #005a8e;
}

.overlay-primary-3:after {
    opacity: 0.3;
    background: #005a8e;
}

.overlay-primary-35:after {
    opacity: 0.35;
    background: #005a8e;
}

.overlay-primary-4:after {
    opacity: 0.4;
    background: #005a8e;
}

.overlay-primary-45:after {
    opacity: 0.45;
    background: #005a8e;
}

.overlay-primary-5:after {
    opacity: 0.5;
    background: #005a8e;
}

.overlay-primary-55:after {
    opacity: 0.55;
    background: #005a8e;
}

.overlay-primary-6:after {
    opacity: 0.6;
    background: #005a8e;
}

.overlay-primary-65:after {
    opacity: 0.65;
    background: #005a8e;
}

.overlay-primary-7:after {
    opacity: 0.7;
    background: #005a8e;
}

.overlay-primary-75:after {
    opacity: 0.75;
    background: #005a8e;
}

.overlay-primary-8:after {
    opacity: 0.8;
    background: #005a8e;
}

.overlay-primary-85:after {
    opacity: 0.85;
    background: #005a8e;
}

.overlay-primary-9:after {
    opacity: 0.9;
    background: #005a8e;
}

.overlay-primary-95:after {
    opacity: 0.95;
    background: #005a8e;
}

.overlay-primary-100:after {
    opacity: 1;
    background: #005a8e;
}

.btn-primary {
    background-color: #005a8e !important;
    border-color: #005a8e !important;
    color: white !important;
}
.btn-primary .fa,
.btn-primary .fas,
.btn-primary .far,
.btn-primary a {
    color: white !important;
}
.btn-primary .dropdown-item {
    color: black !important;
}
.btn-primary:focus {
    box-shadow: none;
}
.btn-primary:hover {
    color: #005a8e !important;
    background-color: white !important;
    border: 1px solid #005a8e;
}
.btn-primary:hover .dropdown-item {
    color: black !important;
    background-color: white;
}
.btn-primary:hover .dropdown-item:hover {
    background-color: #005a8e !important;
    color: white !important;
}
.btn-primary:hover .fa,
.btn-primary:hover .fas,
.btn-primary:hover .far,
.btn-primary:hover a {
    color: #005a8e !important;
}

.outline-primary {
    color: #005a8e !important;
    background-color: transparent;
    background-image: none;
    border-color: #005a8e !important;
}
.outline-primary .fa,
.outline-primary .fas,
.outline-primary .far,
.outline-primary a {
    color: #005a8e !important;
}
.outline-primary:hover {
    color: white !important;
    background-color: #005a8e !important;
    border-color: #005a8e !important;
}
.outline-primary:hover .fa,
.outline-primary:hover .fas,
.outline-primary:hover .far,
.outline-primary:hover a {
    color: white !important;
}
.outline-primary:focus {
    box-shadow: none;
}

.badge-primary {
    background-color: #005a8e !important;
    border: 1px solid #005a8e !important;
    color: white !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.badge-primary-lighten-5 {
    background-color: #006aa8 !important;
    border: 1px solid #006aa8 !important;
    color: white !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.badge-primary-lighten-10 {
    background-color: #007ac1 !important;
    border: 1px solid #007ac1 !important;
    color: white !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.badge-outline-primary {
    background-color: var(--background) !important;
    color: #005a8e !important;
    border: 1px solid #005a8e !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}
.badge-outline-primary:hover {
    background-color: #005a8e !important;
    color: white !important;
    cursor: pointer;
}

.status-primary {
    border-color: #005a8e !important;
}

.bg-primary {
    background-color: #005a8e !important;
}

.color-primary {
    color: #005a8e !important;
}

.overlay-secondary-05:after {
    opacity: 0.05;
    background: #002855;
}

.overlay-secondary-1:after {
    opacity: 0.1;
    background: #002855;
}

.overlay-secondary-15:after {
    opacity: 0.15;
    background: #002855;
}

.overlay-secondary-2:after {
    opacity: 0.2;
    background: #002855;
}

.overlay-secondary-25:after {
    opacity: 0.25;
    background: #002855;
}

.overlay-secondary-3:after {
    opacity: 0.3;
    background: #002855;
}

.overlay-secondary-35:after {
    opacity: 0.35;
    background: #002855;
}

.overlay-secondary-4:after {
    opacity: 0.4;
    background: #002855;
}

.overlay-secondary-45:after {
    opacity: 0.45;
    background: #002855;
}

.overlay-secondary-5:after {
    opacity: 0.5;
    background: #002855;
}

.overlay-secondary-55:after {
    opacity: 0.55;
    background: #002855;
}

.overlay-secondary-6:after {
    opacity: 0.6;
    background: #002855;
}

.overlay-secondary-65:after {
    opacity: 0.65;
    background: #002855;
}

.overlay-secondary-7:after {
    opacity: 0.7;
    background: #002855;
}

.overlay-secondary-75:after {
    opacity: 0.75;
    background: #002855;
}

.overlay-secondary-8:after {
    opacity: 0.8;
    background: #002855;
}

.overlay-secondary-85:after {
    opacity: 0.85;
    background: #002855;
}

.overlay-secondary-9:after {
    opacity: 0.9;
    background: #002855;
}

.overlay-secondary-95:after {
    opacity: 0.95;
    background: #002855;
}

.overlay-secondary-100:after {
    opacity: 1;
    background: #002855;
}

.btn-secondary {
    background-color: #002855 !important;
    border-color: #002855 !important;
    color: white !important;
}
.btn-secondary .fa,
.btn-secondary .fas,
.btn-secondary .far,
.btn-secondary a {
    color: white !important;
}
.btn-secondary .dropdown-item {
    color: black !important;
}
.btn-secondary:focus {
    box-shadow: none;
}
.btn-secondary:hover {
    color: #002855 !important;
    background-color: white !important;
    border: 1px solid #002855;
}
.btn-secondary:hover .dropdown-item {
    color: black !important;
    background-color: white;
}
.btn-secondary:hover .dropdown-item:hover {
    background-color: #002855 !important;
    color: white !important;
}
.btn-secondary:hover .fa,
.btn-secondary:hover .fas,
.btn-secondary:hover .far,
.btn-secondary:hover a {
    color: #002855 !important;
}

.outline-secondary {
    color: #002855 !important;
    background-color: transparent;
    background-image: none;
    border-color: #002855 !important;
}
.outline-secondary .fa,
.outline-secondary .fas,
.outline-secondary .far,
.outline-secondary a {
    color: #002855 !important;
}
.outline-secondary:hover {
    color: white !important;
    background-color: #002855 !important;
    border-color: #002855 !important;
}
.outline-secondary:hover .fa,
.outline-secondary:hover .fas,
.outline-secondary:hover .far,
.outline-secondary:hover a {
    color: white !important;
}
.outline-secondary:focus {
    box-shadow: none;
}

.badge-secondary {
    background-color: #002855 !important;
    border: 1px solid #002855 !important;
    color: white !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.badge-secondary-lighten-5 {
    background-color: #00346f !important;
    border: 1px solid #00346f !important;
    color: white !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.badge-secondary-lighten-10 {
    background-color: #004088 !important;
    border: 1px solid #004088 !important;
    color: white !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.badge-outline-secondary {
    background-color: var(--background) !important;
    color: #002855 !important;
    border: 1px solid #002855 !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}
.badge-outline-secondary:hover {
    background-color: #002855 !important;
    color: white !important;
    cursor: pointer;
}

.status-secondary {
    border-color: #002855 !important;
}

.bg-secondary {
    background-color: var(--accent) !important;
}

.color-secondary {
    color: #002855 !important;
}

.overlay-success-05:after {
    opacity: 0.05;
    background: #4dbc68;
}

.overlay-success-1:after {
    opacity: 0.1;
    background: #4dbc68;
}

.overlay-success-15:after {
    opacity: 0.15;
    background: #4dbc68;
}

.overlay-success-2:after {
    opacity: 0.2;
    background: #4dbc68;
}

.overlay-success-25:after {
    opacity: 0.25;
    background: #4dbc68;
}

.overlay-success-3:after {
    opacity: 0.3;
    background: #4dbc68;
}

.overlay-success-35:after {
    opacity: 0.35;
    background: #4dbc68;
}

.overlay-success-4:after {
    opacity: 0.4;
    background: #4dbc68;
}

.overlay-success-45:after {
    opacity: 0.45;
    background: #4dbc68;
}

.overlay-success-5:after {
    opacity: 0.5;
    background: #4dbc68;
}

.overlay-success-55:after {
    opacity: 0.55;
    background: #4dbc68;
}

.overlay-success-6:after {
    opacity: 0.6;
    background: #4dbc68;
}

.overlay-success-65:after {
    opacity: 0.65;
    background: #4dbc68;
}

.overlay-success-7:after {
    opacity: 0.7;
    background: #4dbc68;
}

.overlay-success-75:after {
    opacity: 0.75;
    background: #4dbc68;
}

.overlay-success-8:after {
    opacity: 0.8;
    background: #4dbc68;
}

.overlay-success-85:after {
    opacity: 0.85;
    background: #4dbc68;
}

.overlay-success-9:after {
    opacity: 0.9;
    background: #4dbc68;
}

.overlay-success-95:after {
    opacity: 0.95;
    background: #4dbc68;
}

.overlay-success-100:after {
    opacity: 1;
    background: #4dbc68;
}

.btn-success {
    background-color: #4dbc68 !important;
    border-color: #4dbc68 !important;
    color: white !important;
}
.btn-success .fa,
.btn-success .fas,
.btn-success .far,
.btn-success a {
    color: white !important;
}
.btn-success .dropdown-item {
    color: black !important;
}
.btn-success:focus {
    box-shadow: none;
}
.btn-success:hover {
    color: #4dbc68 !important;
    background-color: white !important;
    border: 1px solid #4dbc68;
}
.btn-success:hover .dropdown-item {
    color: black !important;
    background-color: white;
}
.btn-success:hover .dropdown-item:hover {
    background-color: #4dbc68 !important;
    color: white !important;
}
.btn-success:hover .fa,
.btn-success:hover .fas,
.btn-success:hover .far,
.btn-success:hover a {
    color: #4dbc68 !important;
}

.outline-success {
    color: #4dbc68 !important;
    background-color: transparent;
    background-image: none;
    border-color: #4dbc68 !important;
}
.outline-success .fa,
.outline-success .fas,
.outline-success .far,
.outline-success a {
    color: #4dbc68 !important;
}
.outline-success:hover {
    color: white !important;
    background-color: #4dbc68 !important;
    border-color: #4dbc68 !important;
}
.outline-success:hover .fa,
.outline-success:hover .fas,
.outline-success:hover .far,
.outline-success:hover a {
    color: white !important;
}
.outline-success:focus {
    box-shadow: none;
}

.badge-success {
    background-color: #4dbc68 !important;
    border: 1px solid #4dbc68 !important;
    color: white !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.badge-success-lighten-5 {
    background-color: #60c378 !important;
    border: 1px solid #60c378 !important;
    color: white !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.badge-success-lighten-10 {
    background-color: #72ca87 !important;
    border: 1px solid #72ca87 !important;
    color: white !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.badge-outline-success {
    background-color: var(--background) !important;
    color: #4dbc68 !important;
    border: 1px solid #4dbc68 !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}
.badge-outline-success:hover {
    background-color: #4dbc68 !important;
    color: white !important;
    cursor: pointer;
}

.status-success {
    border-color: #4dbc68 !important;
}

.bg-success {
    background-color: #4dbc68 !important;
}

.color-success {
    color: #4dbc68 !important;
}

.overlay-warning-05:after {
    opacity: 0.05;
    background: #f6cf4e;
}

.overlay-warning-1:after {
    opacity: 0.1;
    background: #f6cf4e;
}

.overlay-warning-15:after {
    opacity: 0.15;
    background: #f6cf4e;
}

.overlay-warning-2:after {
    opacity: 0.2;
    background: #f6cf4e;
}

.overlay-warning-25:after {
    opacity: 0.25;
    background: #f6cf4e;
}

.overlay-warning-3:after {
    opacity: 0.3;
    background: #f6cf4e;
}

.overlay-warning-35:after {
    opacity: 0.35;
    background: #f6cf4e;
}

.overlay-warning-4:after {
    opacity: 0.4;
    background: #f6cf4e;
}

.overlay-warning-45:after {
    opacity: 0.45;
    background: #f6cf4e;
}

.overlay-warning-5:after {
    opacity: 0.5;
    background: #f6cf4e;
}

.overlay-warning-55:after {
    opacity: 0.55;
    background: #f6cf4e;
}

.overlay-warning-6:after {
    opacity: 0.6;
    background: #f6cf4e;
}

.overlay-warning-65:after {
    opacity: 0.65;
    background: #f6cf4e;
}

.overlay-warning-7:after {
    opacity: 0.7;
    background: #f6cf4e;
}

.overlay-warning-75:after {
    opacity: 0.75;
    background: #f6cf4e;
}

.overlay-warning-8:after {
    opacity: 0.8;
    background: #f6cf4e;
}

.overlay-warning-85:after {
    opacity: 0.85;
    background: #f6cf4e;
}

.overlay-warning-9:after {
    opacity: 0.9;
    background: #f6cf4e;
}

.overlay-warning-95:after {
    opacity: 0.95;
    background: #f6cf4e;
}

.overlay-warning-100:after {
    opacity: 1;
    background: #f6cf4e;
}

.btn-warning {
    background-color: #f6cf4e !important;
    border-color: #f6cf4e !important;
    color: white !important;
}
.btn-warning .fa,
.btn-warning .fas,
.btn-warning .far,
.btn-warning a {
    color: white !important;
}
.btn-warning .dropdown-item {
    color: black !important;
}
.btn-warning:focus {
    box-shadow: none;
}
.btn-warning:hover {
    color: #f6cf4e !important;
    background-color: white !important;
    border: 1px solid #f6cf4e;
}
.btn-warning:hover .dropdown-item {
    color: black !important;
    background-color: white;
}
.btn-warning:hover .dropdown-item:hover {
    background-color: #f6cf4e !important;
    color: white !important;
}
.btn-warning:hover .fa,
.btn-warning:hover .fas,
.btn-warning:hover .far,
.btn-warning:hover a {
    color: #f6cf4e !important;
}

.outline-warning {
    color: #f6cf4e !important;
    background-color: transparent;
    background-image: none;
    border-color: #f6cf4e !important;
}
.outline-warning .fa,
.outline-warning .fas,
.outline-warning .far,
.outline-warning a {
    color: #f6cf4e !important;
}
.outline-warning:hover {
    color: white !important;
    background-color: #f6cf4e !important;
    border-color: #f6cf4e !important;
}
.outline-warning:hover .fa,
.outline-warning:hover .fas,
.outline-warning:hover .far,
.outline-warning:hover a {
    color: white !important;
}
.outline-warning:focus {
    box-shadow: none;
}

.badge-warning {
    background-color: #f6cf4e !important;
    border: 1px solid #f6cf4e !important;
    color: white !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.badge-warning-lighten-5 {
    background-color: #f7d666 !important;
    border: 1px solid #f7d666 !important;
    color: white !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.badge-warning-lighten-10 {
    background-color: #f8dc7f !important;
    border: 1px solid #f8dc7f !important;
    color: white !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.badge-outline-warning {
    background-color: var(--background) !important;
    color: #f6cf4e !important;
    border: 1px solid #f6cf4e !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}
.badge-outline-warning:hover {
    background-color: #f6cf4e !important;
    color: white !important;
    cursor: pointer;
}

.status-warning {
    border-color: #f6cf4e !important;
}

.bg-warning {
    background-color: #f6cf4e !important;
}

.color-warning {
    color: #f6cf4e !important;
}

.length-1 {
    width: calc((100% - 40px) / 1);
}

.length-2 {
    width: calc((100% - 40px) / 2);
}

.length-3 {
    width: calc((100% - 40px) / 3);
}

.length-4 {
    width: calc((100% - 40px) / 4);
}

.length-5 {
    width: calc((100% - 40px) / 5);
}

.width-0 {
    width: 0% !important;
    max-width: 0% !important;
    min-width: 0% !important;
}

.width-1 {
    width: 1% !important;
    max-width: 1% !important;
    min-width: 1% !important;
}

.width-2 {
    width: 2% !important;
    max-width: 2% !important;
    min-width: 2% !important;
}

.width-3 {
    width: 3% !important;
    max-width: 3% !important;
    min-width: 3% !important;
}

.width-4 {
    width: 4% !important;
    max-width: 4% !important;
    min-width: 4% !important;
}

.width-5 {
    width: 5% !important;
    max-width: 5% !important;
    min-width: 5% !important;
}

.width-6 {
    width: 6% !important;
    max-width: 6% !important;
    min-width: 6% !important;
}

.width-7 {
    width: 7% !important;
    max-width: 7% !important;
    min-width: 7% !important;
}

.width-8 {
    width: 8% !important;
    max-width: 8% !important;
    min-width: 8% !important;
}

.width-9 {
    width: 9% !important;
    max-width: 9% !important;
    min-width: 9% !important;
}

.width-10 {
    width: 10% !important;
    max-width: 10% !important;
    min-width: 10% !important;
}

.width-11 {
    width: 11% !important;
    max-width: 11% !important;
    min-width: 11% !important;
}

.width-12 {
    width: 12% !important;
    max-width: 12% !important;
    min-width: 12% !important;
}

.width-13 {
    width: 13% !important;
    max-width: 13% !important;
    min-width: 13% !important;
}

.width-14 {
    width: 14% !important;
    max-width: 14% !important;
    min-width: 14% !important;
}

.width-15 {
    width: 15% !important;
    max-width: 15% !important;
    min-width: 15% !important;
}

.width-20 {
    width: 20% !important;
    max-width: 20% !important;
    min-width: 20% !important;
}

.width-25 {
    width: 25% !important;
    max-width: 25% !important;
    min-width: 25% !important;
}

.width-30 {
    width: 30% !important;
    max-width: 30% !important;
    min-width: 30% !important;
}

.width-35 {
    width: 35% !important;
    max-width: 35% !important;
    min-width: 35% !important;
}

.width-40 {
    width: 40% !important;
    max-width: 40% !important;
    min-width: 40% !important;
}

.width-45 {
    width: 45% !important;
    max-width: 45% !important;
    min-width: 45% !important;
}

.width-50 {
    width: 50% !important;
    max-width: 50% !important;
    min-width: 50% !important;
}

.width-55 {
    width: 55% !important;
    max-width: 55% !important;
    min-width: 55% !important;
}

.width-60 {
    width: 60% !important;
    max-width: 60% !important;
    min-width: 60% !important;
}

.width-65 {
    width: 65% !important;
    max-width: 65% !important;
    min-width: 65% !important;
}

.width-70 {
    width: 70% !important;
    max-width: 70% !important;
    min-width: 70% !important;
}

.width-75 {
    width: 75% !important;
    max-width: 75% !important;
    min-width: 75% !important;
}

.width-80 {
    width: 80% !important;
    max-width: 80% !important;
    min-width: 80% !important;
}

.width-85 {
    width: 85% !important;
    max-width: 85% !important;
    min-width: 85% !important;
}

.width-90 {
    width: 90% !important;
    max-width: 90% !important;
    min-width: 90% !important;
}

.width-95 {
    width: 95% !important;
    max-width: 95% !important;
    min-width: 95% !important;
}

.width-100 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
}

.margin-0 {
    margin: 0px !important;
}

.margin-left-0 {
    margin-left: 0px !important;
}

.margin-right-0 {
    margin-right: 0px !important;
}

.margin-bottom-0 {
    margin-bottom: 0px !important;
}

.margin-top-0 {
    margin-top: 0px !important;
}

.padding-0 {
    padding: 0px !important;
}

.padding-top-0 {
    padding-top: 0px !important;
}

.padding-right-0 {
    padding-right: 0px !important;
}

.padding-bottom-0 {
    padding-bottom: 0px !important;
}

.padding-left-0 {
    padding-left: 0px !important;
}

.margin-1 {
    margin: 1px !important;
}

.margin-left-1 {
    margin-left: 1px !important;
}

.margin-right-1 {
    margin-right: 1px !important;
}

.margin-bottom-1 {
    margin-bottom: 1px !important;
}

.margin-top-1 {
    margin-top: 1px !important;
}

.padding-1 {
    padding: 1px !important;
}

.padding-top-1 {
    padding-top: 1px !important;
}

.padding-right-1 {
    padding-right: 1px !important;
}

.padding-bottom-1 {
    padding-bottom: 1px !important;
}

.padding-left-1 {
    padding-left: 1px !important;
}

.margin-2 {
    margin: 2px !important;
}

.margin-left-2 {
    margin-left: 2px !important;
}

.margin-right-2 {
    margin-right: 2px !important;
}

.margin-bottom-2 {
    margin-bottom: 2px !important;
}

.margin-top-2 {
    margin-top: 2px !important;
}

.padding-2 {
    padding: 2px !important;
}

.padding-top-2 {
    padding-top: 2px !important;
}

.padding-right-2 {
    padding-right: 2px !important;
}

.padding-bottom-2 {
    padding-bottom: 2px !important;
}

.padding-left-2 {
    padding-left: 2px !important;
}

.margin-3 {
    margin: 3px !important;
}

.margin-left-3 {
    margin-left: 3px !important;
}

.margin-right-3 {
    margin-right: 3px !important;
}

.margin-bottom-3 {
    margin-bottom: 3px !important;
}

.margin-top-3 {
    margin-top: 3px !important;
}

.padding-3 {
    padding: 3px !important;
}

.padding-top-3 {
    padding-top: 3px !important;
}

.padding-right-3 {
    padding-right: 3px !important;
}

.padding-bottom-3 {
    padding-bottom: 3px !important;
}

.padding-left-3 {
    padding-left: 3px !important;
}

.margin-4 {
    margin: 4px !important;
}

.margin-left-4 {
    margin-left: 4px !important;
}

.margin-right-4 {
    margin-right: 4px !important;
}

.margin-bottom-4 {
    margin-bottom: 4px !important;
}

.margin-top-4 {
    margin-top: 4px !important;
}

.padding-4 {
    padding: 4px !important;
}

.padding-top-4 {
    padding-top: 4px !important;
}

.padding-right-4 {
    padding-right: 4px !important;
}

.padding-bottom-4 {
    padding-bottom: 4px !important;
}

.padding-left-4 {
    padding-left: 4px !important;
}

.margin-5 {
    margin: 5px !important;
}

.margin-left-5 {
    margin-left: 5px !important;
}

.margin-right-5 {
    margin-right: 5px !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-top-5 {
    margin-top: 5px !important;
}

.padding-5 {
    padding: 5px !important;
}

.padding-top-5 {
    padding-top: 5px !important;
}

.padding-right-5 {
    padding-right: 5px !important;
}

.padding-bottom-5 {
    padding-bottom: 5px !important;
}

.padding-left-5 {
    padding-left: 5px !important;
}

.margin-6 {
    margin: 6px !important;
}

.margin-left-6 {
    margin-left: 6px !important;
}

.margin-right-6 {
    margin-right: 6px !important;
}

.margin-bottom-6 {
    margin-bottom: 6px !important;
}

.margin-top-6 {
    margin-top: 6px !important;
}

.padding-6 {
    padding: 6px !important;
}

.padding-top-6 {
    padding-top: 6px !important;
}

.padding-right-6 {
    padding-right: 6px !important;
}

.padding-bottom-6 {
    padding-bottom: 6px !important;
}

.padding-left-6 {
    padding-left: 6px !important;
}

.margin-7 {
    margin: 7px !important;
}

.margin-left-7 {
    margin-left: 7px !important;
}

.margin-right-7 {
    margin-right: 7px !important;
}

.margin-bottom-7 {
    margin-bottom: 7px !important;
}

.margin-top-7 {
    margin-top: 7px !important;
}

.padding-7 {
    padding: 7px !important;
}

.padding-top-7 {
    padding-top: 7px !important;
}

.padding-right-7 {
    padding-right: 7px !important;
}

.padding-bottom-7 {
    padding-bottom: 7px !important;
}

.padding-left-7 {
    padding-left: 7px !important;
}

.margin-8 {
    margin: 8px !important;
}

.margin-left-8 {
    margin-left: 8px !important;
}

.margin-right-8 {
    margin-right: 8px !important;
}

.margin-bottom-8 {
    margin-bottom: 8px !important;
}

.margin-top-8 {
    margin-top: 8px !important;
}

.padding-8 {
    padding: 8px !important;
}

.padding-top-8 {
    padding-top: 8px !important;
}

.padding-right-8 {
    padding-right: 8px !important;
}

.padding-bottom-8 {
    padding-bottom: 8px !important;
}

.padding-left-8 {
    padding-left: 8px !important;
}

.margin-9 {
    margin: 9px !important;
}

.margin-left-9 {
    margin-left: 9px !important;
}

.margin-right-9 {
    margin-right: 9px !important;
}

.margin-bottom-9 {
    margin-bottom: 9px !important;
}

.margin-top-9 {
    margin-top: 9px !important;
}

.padding-9 {
    padding: 9px !important;
}

.padding-top-9 {
    padding-top: 9px !important;
}

.padding-right-9 {
    padding-right: 9px !important;
}

.padding-bottom-9 {
    padding-bottom: 9px !important;
}

.padding-left-9 {
    padding-left: 9px !important;
}

.margin-10 {
    margin: 10px !important;
}

.margin-left-10 {
    margin-left: 10px !important;
}

.margin-right-10 {
    margin-right: 10px !important;
}

.margin-bottom-10 {
    margin-bottom: 10px !important;
}

.margin-top-10 {
    margin-top: 10px !important;
}

.padding-10 {
    padding: 10px !important;
}

.padding-top-10 {
    padding-top: 10px !important;
}

.padding-right-10 {
    padding-right: 10px !important;
}

.padding-bottom-10 {
    padding-bottom: 10px !important;
}

.padding-left-10 {
    padding-left: 10px !important;
}

.margin-15 {
    margin: 15px !important;
}

.margin-left-15 {
    margin-left: 15px !important;
}

.margin-right-15 {
    margin-right: 15px !important;
}

.margin-bottom-15 {
    margin-bottom: 15px !important;
}

.margin-top-15 {
    margin-top: 15px !important;
}

.padding-15 {
    padding: 15px !important;
}

.padding-top-15 {
    padding-top: 15px !important;
}

.padding-right-15 {
    padding-right: 15px !important;
}

.padding-bottom-15 {
    padding-bottom: 15px !important;
}

.padding-left-15 {
    padding-left: 15px !important;
}

.margin-20 {
    margin: 20px !important;
}

.margin-left-20 {
    margin-left: 20px !important;
}

.margin-right-20 {
    margin-right: 20px !important;
}

.margin-bottom-20 {
    margin-bottom: 20px !important;
}

.margin-top-20 {
    margin-top: 20px !important;
}

.padding-20 {
    padding: 20px !important;
}

.padding-top-20 {
    padding-top: 20px !important;
}

.padding-right-20 {
    padding-right: 20px !important;
}

.padding-bottom-20 {
    padding-bottom: 20px !important;
}

.padding-left-20 {
    padding-left: 20px !important;
}

.margin-25 {
    margin: 25px !important;
}

.margin-left-25 {
    margin-left: 25px !important;
}

.margin-right-25 {
    margin-right: 25px !important;
}

.margin-bottom-25 {
    margin-bottom: 25px !important;
}

.margin-top-25 {
    margin-top: 25px !important;
}

.padding-25 {
    padding: 25px !important;
}

.padding-top-25 {
    padding-top: 25px !important;
}

.padding-right-25 {
    padding-right: 25px !important;
}

.padding-bottom-25 {
    padding-bottom: 25px !important;
}

.padding-left-25 {
    padding-left: 25px !important;
}

.margin-30 {
    margin: 30px !important;
}

.margin-left-30 {
    margin-left: 30px !important;
}

.margin-right-30 {
    margin-right: 30px !important;
}

.margin-bottom-30 {
    margin-bottom: 30px !important;
}

.margin-top-30 {
    margin-top: 30px !important;
}

.padding-30 {
    padding: 30px !important;
}

.padding-top-30 {
    padding-top: 30px !important;
}

.padding-right-30 {
    padding-right: 30px !important;
}

.padding-bottom-30 {
    padding-bottom: 30px !important;
}

.padding-left-30 {
    padding-left: 30px !important;
}

.margin-35 {
    margin: 35px !important;
}

.margin-left-35 {
    margin-left: 35px !important;
}

.margin-right-35 {
    margin-right: 35px !important;
}

.margin-bottom-35 {
    margin-bottom: 35px !important;
}

.margin-top-35 {
    margin-top: 35px !important;
}

.padding-35 {
    padding: 35px !important;
}

.padding-top-35 {
    padding-top: 35px !important;
}

.padding-right-35 {
    padding-right: 35px !important;
}

.padding-bottom-35 {
    padding-bottom: 35px !important;
}

.padding-left-35 {
    padding-left: 35px !important;
}

.margin-40 {
    margin: 40px !important;
}

.margin-left-40 {
    margin-left: 40px !important;
}

.margin-right-40 {
    margin-right: 40px !important;
}

.margin-bottom-40 {
    margin-bottom: 40px !important;
}

.margin-top-40 {
    margin-top: 40px !important;
}

.padding-40 {
    padding: 40px !important;
}

.padding-top-40 {
    padding-top: 40px !important;
}

.padding-right-40 {
    padding-right: 40px !important;
}

.padding-bottom-40 {
    padding-bottom: 40px !important;
}

.padding-left-40 {
    padding-left: 40px !important;
}

.margin-45 {
    margin: 45px !important;
}

.margin-left-45 {
    margin-left: 45px !important;
}

.margin-right-45 {
    margin-right: 45px !important;
}

.margin-bottom-45 {
    margin-bottom: 45px !important;
}

.margin-top-45 {
    margin-top: 45px !important;
}

.padding-45 {
    padding: 45px !important;
}

.padding-top-45 {
    padding-top: 45px !important;
}

.padding-right-45 {
    padding-right: 45px !important;
}

.padding-bottom-45 {
    padding-bottom: 45px !important;
}

.padding-left-45 {
    padding-left: 45px !important;
}

.margin-50 {
    margin: 50px !important;
}

.margin-left-50 {
    margin-left: 50px !important;
}

.margin-right-50 {
    margin-right: 50px !important;
}

.margin-bottom-50 {
    margin-bottom: 50px !important;
}

.margin-top-50 {
    margin-top: 50px !important;
}

.padding-50 {
    padding: 50px !important;
}

.padding-top-50 {
    padding-top: 50px !important;
}

.padding-right-50 {
    padding-right: 50px !important;
}

.padding-bottom-50 {
    padding-bottom: 50px !important;
}

.padding-left-50 {
    padding-left: 50px !important;
}

.margin-55 {
    margin: 55px !important;
}

.margin-left-55 {
    margin-left: 55px !important;
}

.margin-right-55 {
    margin-right: 55px !important;
}

.margin-bottom-55 {
    margin-bottom: 55px !important;
}

.margin-top-55 {
    margin-top: 55px !important;
}

.padding-55 {
    padding: 55px !important;
}

.padding-top-55 {
    padding-top: 55px !important;
}

.padding-right-55 {
    padding-right: 55px !important;
}

.padding-bottom-55 {
    padding-bottom: 55px !important;
}

.padding-left-55 {
    padding-left: 55px !important;
}

.margin-60 {
    margin: 60px !important;
}

.margin-left-60 {
    margin-left: 60px !important;
}

.margin-right-60 {
    margin-right: 60px !important;
}

.margin-bottom-60 {
    margin-bottom: 60px !important;
}

.margin-top-60 {
    margin-top: 60px !important;
}

.padding-60 {
    padding: 60px !important;
}

.padding-top-60 {
    padding-top: 60px !important;
}

.padding-right-60 {
    padding-right: 60px !important;
}

.padding-bottom-60 {
    padding-bottom: 60px !important;
}

.padding-left-60 {
    padding-left: 60px !important;
}

.margin-65 {
    margin: 65px !important;
}

.margin-left-65 {
    margin-left: 65px !important;
}

.margin-right-65 {
    margin-right: 65px !important;
}

.margin-bottom-65 {
    margin-bottom: 65px !important;
}

.margin-top-65 {
    margin-top: 65px !important;
}

.padding-65 {
    padding: 65px !important;
}

.padding-top-65 {
    padding-top: 65px !important;
}

.padding-right-65 {
    padding-right: 65px !important;
}

.padding-bottom-65 {
    padding-bottom: 65px !important;
}

.padding-left-65 {
    padding-left: 65px !important;
}

.margin-70 {
    margin: 70px !important;
}

.margin-left-70 {
    margin-left: 70px !important;
}

.margin-right-70 {
    margin-right: 70px !important;
}

.margin-bottom-70 {
    margin-bottom: 70px !important;
}

.margin-top-70 {
    margin-top: 70px !important;
}

.padding-70 {
    padding: 70px !important;
}

.padding-top-70 {
    padding-top: 70px !important;
}

.padding-right-70 {
    padding-right: 70px !important;
}

.padding-bottom-70 {
    padding-bottom: 70px !important;
}

.padding-left-70 {
    padding-left: 70px !important;
}

.margin-75 {
    margin: 75px !important;
}

.margin-left-75 {
    margin-left: 75px !important;
}

.margin-right-75 {
    margin-right: 75px !important;
}

.margin-bottom-75 {
    margin-bottom: 75px !important;
}

.margin-top-75 {
    margin-top: 75px !important;
}

.padding-75 {
    padding: 75px !important;
}

.padding-top-75 {
    padding-top: 75px !important;
}

.padding-right-75 {
    padding-right: 75px !important;
}

.padding-bottom-75 {
    padding-bottom: 75px !important;
}

.padding-left-75 {
    padding-left: 75px !important;
}

.margin-80 {
    margin: 80px !important;
}

.margin-left-80 {
    margin-left: 80px !important;
}

.margin-right-80 {
    margin-right: 80px !important;
}

.margin-bottom-80 {
    margin-bottom: 80px !important;
}

.margin-top-80 {
    margin-top: 80px !important;
}

.padding-80 {
    padding: 80px !important;
}

.padding-top-80 {
    padding-top: 80px !important;
}

.padding-right-80 {
    padding-right: 80px !important;
}

.padding-bottom-80 {
    padding-bottom: 80px !important;
}

.padding-left-80 {
    padding-left: 80px !important;
}

.margin-85 {
    margin: 85px !important;
}

.margin-left-85 {
    margin-left: 85px !important;
}

.margin-right-85 {
    margin-right: 85px !important;
}

.margin-bottom-85 {
    margin-bottom: 85px !important;
}

.margin-top-85 {
    margin-top: 85px !important;
}

.padding-85 {
    padding: 85px !important;
}

.padding-top-85 {
    padding-top: 85px !important;
}

.padding-right-85 {
    padding-right: 85px !important;
}

.padding-bottom-85 {
    padding-bottom: 85px !important;
}

.padding-left-85 {
    padding-left: 85px !important;
}

.margin-90 {
    margin: 90px !important;
}

.margin-left-90 {
    margin-left: 90px !important;
}

.margin-right-90 {
    margin-right: 90px !important;
}

.margin-bottom-90 {
    margin-bottom: 90px !important;
}

.margin-top-90 {
    margin-top: 90px !important;
}

.padding-90 {
    padding: 90px !important;
}

.padding-top-90 {
    padding-top: 90px !important;
}

.padding-right-90 {
    padding-right: 90px !important;
}

.padding-bottom-90 {
    padding-bottom: 90px !important;
}

.padding-left-90 {
    padding-left: 90px !important;
}

.margin-95 {
    margin: 95px !important;
}

.margin-left-95 {
    margin-left: 95px !important;
}

.margin-right-95 {
    margin-right: 95px !important;
}

.margin-bottom-95 {
    margin-bottom: 95px !important;
}

.margin-top-95 {
    margin-top: 95px !important;
}

.padding-95 {
    padding: 95px !important;
}

.padding-top-95 {
    padding-top: 95px !important;
}

.padding-right-95 {
    padding-right: 95px !important;
}

.padding-bottom-95 {
    padding-bottom: 95px !important;
}

.padding-left-95 {
    padding-left: 95px !important;
}

.margin-100 {
    margin: 100px !important;
}

.margin-left-100 {
    margin-left: 100px !important;
}

.margin-right-100 {
    margin-right: 100px !important;
}

.margin-bottom-100 {
    margin-bottom: 100px !important;
}

.margin-top-100 {
    margin-top: 100px !important;
}

.padding-100 {
    padding: 100px !important;
}

.padding-top-100 {
    padding-top: 100px !important;
}

.padding-right-100 {
    padding-right: 100px !important;
}

.padding-bottom-100 {
    padding-bottom: 100px !important;
}

.padding-left-100 {
    padding-left: 100px !important;
}

.border-radius-0 {
    border-radius: 0px !important;
}

.border-top-left-radius-0 {
    border-top-left-radius: 0px !important;
}

.border-top-right-radius-0 {
    border-top-right-radius: 0px !important;
}

.border-bottom-right-radius-0 {
    border-bottom-right-radius: 0px !important;
}

.border-bottom-left-radius-0 {
    border-bottom-left-radius: 0px !important;
}

.border-radius-1 {
    border-radius: 1px !important;
}

.border-top-left-radius-1 {
    border-top-left-radius: 1px !important;
}

.border-top-right-radius-1 {
    border-top-right-radius: 1px !important;
}

.border-bottom-right-radius-1 {
    border-bottom-right-radius: 1px !important;
}

.border-bottom-left-radius-1 {
    border-bottom-left-radius: 1px !important;
}

.border-radius-2 {
    border-radius: 2px !important;
}

.border-top-left-radius-2 {
    border-top-left-radius: 2px !important;
}

.border-top-right-radius-2 {
    border-top-right-radius: 2px !important;
}

.border-bottom-right-radius-2 {
    border-bottom-right-radius: 2px !important;
}

.border-bottom-left-radius-2 {
    border-bottom-left-radius: 2px !important;
}

.border-radius-3 {
    border-radius: 3px !important;
}

.border-top-left-radius-3 {
    border-top-left-radius: 3px !important;
}

.border-top-right-radius-3 {
    border-top-right-radius: 3px !important;
}

.border-bottom-right-radius-3 {
    border-bottom-right-radius: 3px !important;
}

.border-bottom-left-radius-3 {
    border-bottom-left-radius: 3px !important;
}

.border-radius-4 {
    border-radius: 4px !important;
}

.border-top-left-radius-4 {
    border-top-left-radius: 4px !important;
}

.border-top-right-radius-4 {
    border-top-right-radius: 4px !important;
}

.border-bottom-right-radius-4 {
    border-bottom-right-radius: 4px !important;
}

.border-bottom-left-radius-4 {
    border-bottom-left-radius: 4px !important;
}

.border-radius-5 {
    border-radius: 5px !important;
}

.border-top-left-radius-5 {
    border-top-left-radius: 5px !important;
}

.border-top-right-radius-5 {
    border-top-right-radius: 5px !important;
}

.border-bottom-right-radius-5 {
    border-bottom-right-radius: 5px !important;
}

.border-bottom-left-radius-5 {
    border-bottom-left-radius: 5px !important;
}

.border-radius-6 {
    border-radius: 6px !important;
}

.border-top-left-radius-6 {
    border-top-left-radius: 6px !important;
}

.border-top-right-radius-6 {
    border-top-right-radius: 6px !important;
}

.border-bottom-right-radius-6 {
    border-bottom-right-radius: 6px !important;
}

.border-bottom-left-radius-6 {
    border-bottom-left-radius: 6px !important;
}

.border-radius-7 {
    border-radius: 7px !important;
}

.border-top-left-radius-7 {
    border-top-left-radius: 7px !important;
}

.border-top-right-radius-7 {
    border-top-right-radius: 7px !important;
}

.border-bottom-right-radius-7 {
    border-bottom-right-radius: 7px !important;
}

.border-bottom-left-radius-7 {
    border-bottom-left-radius: 7px !important;
}

.border-radius-8 {
    border-radius: 8px !important;
}

.border-top-left-radius-8 {
    border-top-left-radius: 8px !important;
}

.border-top-right-radius-8 {
    border-top-right-radius: 8px !important;
}

.border-bottom-right-radius-8 {
    border-bottom-right-radius: 8px !important;
}

.border-bottom-left-radius-8 {
    border-bottom-left-radius: 8px !important;
}

.border-radius-9 {
    border-radius: 9px !important;
}

.border-top-left-radius-9 {
    border-top-left-radius: 9px !important;
}

.border-top-right-radius-9 {
    border-top-right-radius: 9px !important;
}

.border-bottom-right-radius-9 {
    border-bottom-right-radius: 9px !important;
}

.border-bottom-left-radius-9 {
    border-bottom-left-radius: 9px !important;
}

.border-radius-10 {
    border-radius: 10px !important;
}

.border-top-left-radius-10 {
    border-top-left-radius: 10px !important;
}

.border-top-right-radius-10 {
    border-top-right-radius: 10px !important;
}

.border-bottom-right-radius-10 {
    border-bottom-right-radius: 10px !important;
}

.border-bottom-left-radius-10 {
    border-bottom-left-radius: 10px !important;
}

.border-radius-15 {
    border-radius: 15px !important;
}

.border-top-left-radius-15 {
    border-top-left-radius: 15px !important;
}

.border-top-right-radius-15 {
    border-top-right-radius: 15px !important;
}

.border-bottom-right-radius-15 {
    border-bottom-right-radius: 15px !important;
}

.border-bottom-left-radius-15 {
    border-bottom-left-radius: 15px !important;
}

.border-radius-20 {
    border-radius: 20px !important;
}

.border-top-left-radius-20 {
    border-top-left-radius: 20px !important;
}

.border-top-right-radius-20 {
    border-top-right-radius: 20px !important;
}

.border-bottom-right-radius-20 {
    border-bottom-right-radius: 20px !important;
}

.border-bottom-left-radius-20 {
    border-bottom-left-radius: 20px !important;
}

.border-radius-25 {
    border-radius: 25px !important;
}

.border-top-left-radius-25 {
    border-top-left-radius: 25px !important;
}

.border-top-right-radius-25 {
    border-top-right-radius: 25px !important;
}

.border-bottom-right-radius-25 {
    border-bottom-right-radius: 25px !important;
}

.border-bottom-left-radius-25 {
    border-bottom-left-radius: 25px !important;
}

.border-radius-30 {
    border-radius: 30px !important;
}

.border-top-left-radius-30 {
    border-top-left-radius: 30px !important;
}

.border-top-right-radius-30 {
    border-top-right-radius: 30px !important;
}

.border-bottom-right-radius-30 {
    border-bottom-right-radius: 30px !important;
}

.border-bottom-left-radius-30 {
    border-bottom-left-radius: 30px !important;
}

.border-radius-35 {
    border-radius: 35px !important;
}

.border-top-left-radius-35 {
    border-top-left-radius: 35px !important;
}

.border-top-right-radius-35 {
    border-top-right-radius: 35px !important;
}

.border-bottom-right-radius-35 {
    border-bottom-right-radius: 35px !important;
}

.border-bottom-left-radius-35 {
    border-bottom-left-radius: 35px !important;
}

.border-radius-40 {
    border-radius: 40px !important;
}

.border-top-left-radius-40 {
    border-top-left-radius: 40px !important;
}

.border-top-right-radius-40 {
    border-top-right-radius: 40px !important;
}

.border-bottom-right-radius-40 {
    border-bottom-right-radius: 40px !important;
}

.border-bottom-left-radius-40 {
    border-bottom-left-radius: 40px !important;
}

.border-radius-45 {
    border-radius: 45px !important;
}

.border-top-left-radius-45 {
    border-top-left-radius: 45px !important;
}

.border-top-right-radius-45 {
    border-top-right-radius: 45px !important;
}

.border-bottom-right-radius-45 {
    border-bottom-right-radius: 45px !important;
}

.border-bottom-left-radius-45 {
    border-bottom-left-radius: 45px !important;
}

.border-radius-50 {
    border-radius: 50px !important;
}

.border-top-left-radius-50 {
    border-top-left-radius: 50px !important;
}

.border-top-right-radius-50 {
    border-top-right-radius: 50px !important;
}

.border-bottom-right-radius-50 {
    border-bottom-right-radius: 50px !important;
}

.border-bottom-left-radius-50 {
    border-bottom-left-radius: 50px !important;
}

.border-radius-55 {
    border-radius: 55px !important;
}

.border-top-left-radius-55 {
    border-top-left-radius: 55px !important;
}

.border-top-right-radius-55 {
    border-top-right-radius: 55px !important;
}

.border-bottom-right-radius-55 {
    border-bottom-right-radius: 55px !important;
}

.border-bottom-left-radius-55 {
    border-bottom-left-radius: 55px !important;
}

.border-radius-60 {
    border-radius: 60px !important;
}

.border-top-left-radius-60 {
    border-top-left-radius: 60px !important;
}

.border-top-right-radius-60 {
    border-top-right-radius: 60px !important;
}

.border-bottom-right-radius-60 {
    border-bottom-right-radius: 60px !important;
}

.border-bottom-left-radius-60 {
    border-bottom-left-radius: 60px !important;
}

.border-radius-65 {
    border-radius: 65px !important;
}

.border-top-left-radius-65 {
    border-top-left-radius: 65px !important;
}

.border-top-right-radius-65 {
    border-top-right-radius: 65px !important;
}

.border-bottom-right-radius-65 {
    border-bottom-right-radius: 65px !important;
}

.border-bottom-left-radius-65 {
    border-bottom-left-radius: 65px !important;
}

.border-radius-70 {
    border-radius: 70px !important;
}

.border-top-left-radius-70 {
    border-top-left-radius: 70px !important;
}

.border-top-right-radius-70 {
    border-top-right-radius: 70px !important;
}

.border-bottom-right-radius-70 {
    border-bottom-right-radius: 70px !important;
}

.border-bottom-left-radius-70 {
    border-bottom-left-radius: 70px !important;
}

.border-radius-75 {
    border-radius: 75px !important;
}

.border-top-left-radius-75 {
    border-top-left-radius: 75px !important;
}

.border-top-right-radius-75 {
    border-top-right-radius: 75px !important;
}

.border-bottom-right-radius-75 {
    border-bottom-right-radius: 75px !important;
}

.border-bottom-left-radius-75 {
    border-bottom-left-radius: 75px !important;
}

.border-radius-80 {
    border-radius: 80px !important;
}

.border-top-left-radius-80 {
    border-top-left-radius: 80px !important;
}

.border-top-right-radius-80 {
    border-top-right-radius: 80px !important;
}

.border-bottom-right-radius-80 {
    border-bottom-right-radius: 80px !important;
}

.border-bottom-left-radius-80 {
    border-bottom-left-radius: 80px !important;
}

.border-radius-85 {
    border-radius: 85px !important;
}

.border-top-left-radius-85 {
    border-top-left-radius: 85px !important;
}

.border-top-right-radius-85 {
    border-top-right-radius: 85px !important;
}

.border-bottom-right-radius-85 {
    border-bottom-right-radius: 85px !important;
}

.border-bottom-left-radius-85 {
    border-bottom-left-radius: 85px !important;
}

.border-radius-90 {
    border-radius: 90px !important;
}

.border-top-left-radius-90 {
    border-top-left-radius: 90px !important;
}

.border-top-right-radius-90 {
    border-top-right-radius: 90px !important;
}

.border-bottom-right-radius-90 {
    border-bottom-right-radius: 90px !important;
}

.border-bottom-left-radius-90 {
    border-bottom-left-radius: 90px !important;
}

.border-radius-95 {
    border-radius: 95px !important;
}

.border-top-left-radius-95 {
    border-top-left-radius: 95px !important;
}

.border-top-right-radius-95 {
    border-top-right-radius: 95px !important;
}

.border-bottom-right-radius-95 {
    border-bottom-right-radius: 95px !important;
}

.border-bottom-left-radius-95 {
    border-bottom-left-radius: 95px !important;
}

.border-radius-100 {
    border-radius: 100px !important;
}

.border-top-left-radius-100 {
    border-top-left-radius: 100px !important;
}

.border-top-right-radius-100 {
    border-top-right-radius: 100px !important;
}

.border-bottom-right-radius-100 {
    border-bottom-right-radius: 100px !important;
}

.border-bottom-left-radius-100 {
    border-bottom-left-radius: 100px !important;
}
