#select-dates-modal {
    .modal-wrapper {
        overflow-y: visible;
    }
    .modal-content-body {
        .form-select-dates {
            padding: 0 !important;
            box-shadow: none !important;
            border-radius: 0 !important;
            width: inherit;

            label {
                margin-bottom: 0 !important;
            }
        }
    }
}
