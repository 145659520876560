#app-container #layout-container #wrapper {
    display: flex;
    position: relative;
}
#app-container #layout-container #wrapper #main {
    width: 100%;
    height: calc(100vh - var(--header-height));
}

@media (max-width: 1024px) {
    #layout-container #wrapper .overlay {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
    }
    #layout-container #wrapper .overlay.expanded {
        display: block;
    }
}
@media (min-width: 1025px) {
    #layout-container #wrapper .overlay {
        display: none;
    }
}
